import React, {useState} from "react";
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n";
import Layout from "../components/Layout";
import HelpMenu from "../components/HelpMenu";
import QuestionEN5 from "../components/Questions/QuestionEN5";
import QuestionDE5 from "../components/Questions/QuestionDE5";
import QuestionFR5 from "../components/Questions/QuestionFR5";

export default function HelpPage() {
    const { locale } = useLocalization();
    const [showHelpMenu, setShowHelpMenu] = useState(true);
        
    const toggleMenu = () => {
        setShowHelpMenu(prev => !prev);
    }

    return (
        <Layout>
            <main className="static help-wrap">               
                <div className="hamburger-help-wrap">
                    <div className={showHelpMenu ? "hamburger-help mobile-only open" : "hamburger-help mobile-only"} onClick={toggleMenu}> </div>     
                </div>
                <div className={showHelpMenu ? "help-menu open" : "help-menu close"}><HelpMenu /></div>
                <div className={showHelpMenu ? "help-content close" : "help-content open"}>
                    { (locale == "en") && <QuestionEN5 /> }
                    { (locale == "de") && <QuestionDE5 /> }
                    { (locale == "fr") && <QuestionFR5 /> }
                </div>
            </main>
        </Layout>
    );
}
